import React, { FC } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { BasicTemplate } from "@templates/BasicTemplate";
import { SecondaryHero } from "@components/molecules/SecondaryHero";
import { ReactComponent as Vector } from "@vectors/career-hero.svg";
import { graphql } from "gatsby";
import { WantedPositions } from "@components/molecules/WantedPositions";
import { JoinToUs } from "@components/molecules/JoinToUs";
import { Routes } from "@routes";
import { getCareerSlug } from "@utils/getCareerSlug";

const Career: FC<{ data: Data }> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <BasicTemplate
      title={t("common:title-career")}
      description={t("common:description-career")}
      renderHero={() => (
        <SecondaryHero
          vector={Vector}
          title={t("hero:title-career")}
          description={t("hero:career-description")}
        />
      )}
      withoutNewsletter
    >
      <WantedPositions
        positions={data.allStrapiCareer.nodes.map(
          ({ name, tags, description, strapi_id }) => ({
            name,
            description: description.data.description,
            tags: tags.map((tag) => tag.name),
            href: `${Routes.Career}${getCareerSlug(strapi_id)}/`,
          })
        )}
      />
      <JoinToUs />
    </BasicTemplate>
  );
};

interface Data {
  allStrapiCareer: {
    nodes: {
      strapi_id: string;
      name: string;
      tags: {
        name: string;
      }[];
      description: {
        data: {
          description: string;
        };
      };
      type: string;
    }[];
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allStrapiCareer(filter: { locale: { eq: $language } }) {
      nodes {
        strapi_id
        name
        tags {
          name
        }
        description {
          data {
            description
          }
        }
        type
      }
    }
  }
`;

export default Career;
