import React  from "react";
import styled from "styled-components";
import { stylesWrapper } from "@theme/commonStyles";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { Button } from "@components/atoms/Button";
import HtmlParser from "react-html-parser";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { breakpoints } from "@theme/breakpoints";

const StyledWrapper = styled.section`
  ${stylesWrapper};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
  margin: 160px auto 0;

  ${breakpoints.laptopMax`
    margin: 120px auto 0;
  `}

  ${breakpoints.tabletMax`
    margin: 10px auto 0;
    flex-direction: column-reverse;
  `}

  ${breakpoints.phoneMax`
    margin: 90px auto 0;
  `}
`;

const StyledLeftWrapper = styled.div`
  max-width: 600px;
  margin-right: 60px;
  min-width: 350px;

  ${breakpoints.tabletMax`
    margin-right: 0;
    margin-top: 120px;
    min-width: unset;
  `};

  ${breakpoints.phoneMax`
    margin-top: 80px;
  `};

  @media (max-width: 520px) {
    margin-top: 60px;
  }
`;

const StyledRightWrapper = styled.div`
  max-width: 700px;
  position: relative;
`;

const StyledBigImage = styled(GatsbyImage)`
  border-radius: 20px;
  transform: translateZ(0);
  overflow: hidden;
`;

const StyledSmallImageWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(5%, 30%, 0);
  border-radius: 30px;
  padding: 10px;
  background: ${({ theme }) => theme.background};

  .gatsby-image-wrapper {
    border-radius: 20px;
    transform: translateZ(0);
    width: 20vw;
    max-width: 300px;
    height: 15vw;
    max-height: 220px;
  }

  ${breakpoints.tabletMax`
    border-radius: 20px;
    
   .gatsby-image-wrapper {
     width: 40vw;
     height: 30vw;
     border-radius: 10px;
   }
  `}
`;

const StyledHeadline = styled(SectionHeadline)``;

const StyledParagraph = styled.p`
  line-height: 1.8;
  margin: 0;
`;

const StyledButton = styled(Button)`
  margin-top: 30px;
  display: inline-flex;

  ${breakpoints.tabletMax`
    margin-left: auto;
    margin-right: auto;
  `}
`;

export const JoinToUs = () => {
  const { t } = useTranslation("join-to-us");
  const { t: ct } = useTranslation("contact")
  const { bigImage, smallImage } = useStaticQuery<Query>(query);

  return (
    <StyledWrapper>
      <StyledLeftWrapper>
        <StyledHeadline>{HtmlParser(t("title"))}</StyledHeadline>
        <StyledParagraph>{HtmlParser(t("description"))}</StyledParagraph>
        <StyledButton
         forwardedAs="a"
         href={`mailto:${ct("our-email")}`}
        >
          {t("button")}
        </StyledButton>
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <StyledBigImage
          image={getImage(bigImage.childImageSharp.gatsbyImageData)}
          alt=""
        />
        <StyledSmallImageWrapper>
          <GatsbyImage
            alt=""
            image={getImage(smallImage.childImageSharp.gatsbyImageData)}
          />
        </StyledSmallImageWrapper>
      </StyledRightWrapper>
    </StyledWrapper>
  );
};

interface Query {
  bigImage: ChildImageSharp;
  smallImage: ChildImageSharp;
}

const query = graphql`
  {
    bigImage: file(name: { eq: "why-us" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
    smallImage: file(name: { eq: "small-image-contact" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
  }
`;
