import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { stylesWrapper } from "@theme/commonStyles";
import { Position } from "@components/atoms/Position";
import { breakpoints } from "@theme/breakpoints";

const StyledWrapper = styled.section`
  ${stylesWrapper}
`;

const StyledHeadline = styled(SectionHeadline)`
  margin-bottom: 120px;

  ${breakpoints.tabletMax`
    margin-bottom: 80px;
  `}

  ${breakpoints.phoneMax`
    margin-bottom: 40px;
  `}
`;

const StyledPositionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 45px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  
  ${breakpoints.tabletMax`
    grid-template-columns: 1fr;
    gap: 20px;
  `};
`;

const StyledPosition = styled(Position)``;

export const WantedPositions: FC<Props> = ({ positions }) => {
  const { t } = useTranslation("wanted-positions");

  return (
    <StyledWrapper>
      <StyledHeadline dangerouslySetInnerHTML={{ __html: t("title") }} />

      <StyledPositionsWrapper>
        {positions.map(({ name, tags, description, href }) => (
          <StyledPosition
            key={name}
            name={name}
            href={href}
            tags={tags.map((item) => ({ content: item, color: "#2264f2" }))}
            description={description}
          />
        ))}
      </StyledPositionsWrapper>
    </StyledWrapper>
  );
};

interface Props {
  positions: {
    href: string;
    name: string;
    tags: string[];
    description: string;
  }[];
}
